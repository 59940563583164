var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"border",attrs:{"headers":_vm.headers,"items":_vm.designs.data,"items-per-page":_vm.paginationParams.per_page,"page":_vm.paginationParams.page,"server-items-length":_vm.designs.total,"loading":_vm.loading,"sort-by":"id","footer-props":_vm.footerProps},on:{"update:page":_vm.changePage,"update:items-per-page":_vm.changeItemsPerPage,"update:sort-by":_vm.sortBy,"update:sort-desc":_vm.sortDesc},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-dialog',{attrs:{"fullscreen":"","max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},on),[_vm._v(_vm._s(_vm.trans('buttons.landingDesign.newItem')))])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.dialogLoaded)?_c('landing-design-form',{attrs:{"options":_vm.options,"edited-item":_vm.editedItem},on:{"saved":_vm.onSave,"close":_vm.close}}):_vm._e()],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.options.languagesKeyed)?_c('v-menu',{attrs:{"top":"","close-on-content-click":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('v-list',_vm._l((item.datas),function(data){return _c('v-list-item',{key:data.id,attrs:{"href":_vm.route('admin.landingDesigns.preview', item.id, data.language.id),"target":"_blank"}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.options.languagesKeyed[data.language_id]))])],1)}),1)],1):_vm._e(),_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}},{key:"item.datas",fn:function(ref){
var item = ref.item;
return [(Object.keys(_vm.translationProgress).length)?_vm._l((_vm.translationProgress[item.id]),function(params,ddataId){return _c('v-chip',{key:'ddata-'+ddataId,staticClass:"ma-2",attrs:{"color":params.color,"text-color":"white"}},[_c('v-progress-circular',{staticClass:"mr-4 small-caption",attrs:{"value":params.value,"color":"white","size":"24"}},[_vm._v(_vm._s(params.value))]),_vm._v(" "+_vm._s(params.label)+" ")],1)}):_vm._e()]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("mysqlDate")(item.created_at))+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }