<template>
    <v-data-table
        :headers="headers"
        :items="designs.data"
        :items-per-page="paginationParams.per_page"
        :page="paginationParams.page"
        :server-items-length="designs.total"
        :loading="loading"
        sort-by="id"
        class="border"
        @update:page="changePage"
        @update:items-per-page="changeItemsPerPage"
        :footer-props="footerProps"
        @update:sort-by="sortBy"
        @update:sort-desc="sortDesc"

    >
        <template v-slot:top>
            <v-toolbar flat color="white">
                <v-dialog v-model="dialog" fullscreen max-width="500px">
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" dark class="mb-2" v-on="on">{{ trans('buttons.landingDesign.newItem') }}</v-btn>
                    </template>
                    <landing-design-form v-if="dialogLoaded" :options="options" :edited-item="editedItem" v-on:saved="onSave" v-on:close="close"></landing-design-form>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
            <v-menu
                    v-if="options.languagesKeyed"
                    top
                    close-on-content-click
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                            icon
                            small
                            class="mr-2"
                            v-bind="attrs"
                            v-on="on"
                    ><v-icon>mdi-eye</v-icon></v-btn>
                </template>
                <v-list>
                    <v-list-item
                            v-for="data in item.datas"
                            :key="data.id"
                            :href="route('admin.landingDesigns.preview', item.id, data.language.id)"
                            target="_blank"
                    >
                        <v-list-item-title>{{ options.languagesKeyed[data.language_id] }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-btn icon small class="mr-2" @click="editItem(item)"><v-icon>mdi-pencil</v-icon></v-btn>
            <v-btn icon small @click="deleteItem(item)"><v-icon>mdi-delete</v-icon></v-btn>
        </template>
        <template v-slot:item.datas="{ item }">
            <template v-if="Object.keys(translationProgress).length">
                <v-chip
                    v-for="(params, ddataId) in translationProgress[item.id]"
                    :key="'ddata-'+ddataId"
                    class="ma-2"
                    :color="params.color"
                    text-color="white"
                >
                    <v-progress-circular
                        :value="params.value"
                        color="white"
                        class="mr-4 small-caption"
                        size="24"
                    >{{ params.value }}</v-progress-circular>
                    {{ params.label }}
                </v-chip>
            </template>
        </template>
        <template v-slot:item.created_at="{ item }">
            {{ item.created_at | mysqlDate }}
        </template>


    </v-data-table>
</template>

<script>
    import tableWithPagination from "@/mixins/tableWithPagination";

    export default {
        name: "LandingDesignsTable",
        components: {
            LandingDesignForm: () => import('@/components/admin/landingDesign/LandingDesignForm'),
        },
        mixins: [tableWithPagination],
        data() {
            return {
                dialog: false,
                dialogLoaded: false,
                designs: {},
                headers: [
                    { text: "id", sortable: false, value: "id" },
                    { text: this.trans("fields.common.label"), value: "label", sortable: false },
                    { text: this.trans("fields.common.landingDesignDatas"), value: "datas", sortable: false },
                    { text: this.trans("fields.common.created_at_m"), value: "created_at", sortable: false },
                    { text: this.trans("fields.common.actions"), value: "actions", sortable: false },
                ],
                paginationParams: {
                    page: 1,
                    per_page: 10,
                },
                url: this.route('admin.landingDesigns.index'),

                editedItem: {},
                defaultItem: {
                    id: "",
                    label: "",
                    html: "",
                    scope: null,
                    user_id: null,
                    offerTypesArr: [],
                    itemsTemplates: {},
                    tokens: "",
                },
            }
        },
        created() {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.initialize();
            this.loadOptions({languages: true, offerTypes: true, landingDesignScopes: true});
        },
        watch: {
            dialog(val) {
                if (val){
                    this.dialogLoaded = true
                } else {
                    this.close();
                }
            }
        },
        methods: {
            onSave(){
                this.close();
                this.initialize();
            },
            editItem(item) {
                this.editedItem = JSON.parse(JSON.stringify(item));
                this.dialog = true;
                this.dialogLoaded = true;
            },
            deleteItem(item) {
                confirm("Are you sure you want to delete this item?") &&
                console.log(['deleted', item]);
            },

            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.dialogLoaded = false;
                }, 300);
            },


        },
        computed: {
            paginationItems: {
                get(){
                    return this.designs;
                },
                set(v){
                    this.designs = v;
                }
            },
            translationProgress(){
                let progress = {};
                if (!this.options.languagesKeyed) return {};
                _.each(this.designs.data, (design) => {
                    progress[design.id] = {};
                    let tokens = JSON.parse(design.tokens);
                    _.each(design.datas, (item) => {
                        let itemDataLength = item.data ? _.filter(Object.values(item.data), (token) => !!token ).length : 0;
                        let designTokensLength = Math.max(1, ( tokens ? tokens.length : 0 ));
                        let value = Math.floor(itemDataLength / designTokensLength * 100 );
                        progress[design.id][item.id] = {
                            value: value,
                            color: value < 100 ? 'red' : 'green',
                            label: this.options.languagesKeyed[item.language_id]
                        }
                    });
                });

                return progress;
            }

        }
    }
</script>

<style scoped>
    .small-caption {
        font-size: 9px;
        margin-left: -6px;
    }
</style>
